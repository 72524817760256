<template>
  <v-app id="inspire">
    <dashboard-core-drawer :toggled="toggled" @toggled="onToggleClick" />
    <dashboard-core-app-bar @toggle="onToggleClick" />
    <v-main>
      <router-view />
      <go-top bg-color="#86BC26" :size="35" :bottom="30" :has-outline="false"></go-top>
    </v-main>
  </v-app>
</template>
<script>
import GoTop from '@inotom/vue-go-top';

export default {
  components: {
    DashboardCoreAppBar: () => import("../../components/core/AppBar"),
    DashboardCoreDrawer: () => import("../../components/core/Drawer"),
    GoTop
    // DashboardCoreView: () => import('./components/core/View'),
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    onToggleClick() {
      this.toggled = !this.toggled;
    }
  }
};
</script>
